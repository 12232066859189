<template>
  <be-modal
    :id="id"
    :title="modalTitle"
    size="lg"
    @shown="resetForm"
    @close="resetForm"
  >
    <label for="message">
      {{ $t("components.meetings.material.minutes.comments.message_label") }}
    </label>

    <be-form-textarea
      id="message"
      class="mb-2"
      :rows="5"
      autofocus
      @input="setMessage"
    />

    <label for="dueAt">
      {{
        forReview
          ? $t("components.meetings.material.minutes.comments.due_at")
          : $t("components.meetings.material.minutes.comments.comments_due_at")
      }}
    </label>

    <datepicker-future-buttons
      id="dueAt"
      v-model="dueAt"
      show-next-meeting
      display-inline
      :get-meeting-date="getNextMeetingDate"
    />

    <div
      class="text-muted my-2 small"
      v-text="$t('components.meetings.material.minutes.comments.empty_date')"
    />

    <be-form-group v-if="mode === 'review'">
      <be-form-checkbox v-model="informBoardMembers" label-sr-only>
        {{
          $t(
            "components.meetings.material.minutes.comments.inform_board_members_on_review_completion"
          )
        }}
      </be-form-checkbox>
    </be-form-group>

    <be-form-group
      v-if="mode === 'review' && platformEnabledAndSubscribed('e-signature')"
      label-for="send-for-signing"
      :label="
        $t(
          'components.meetings.material.minutes.comments.send_for_signing_label'
        )
      "
    >
      <be-form-radio-group
        id="send-for-signing"
        v-model="sendForSigningOnceReviewed"
        stacked
        :options="sendForSigningOnceReviewedOptions"
      >
      </be-form-radio-group>
    </be-form-group>

    <be-table
      :items="attendancesAndSelections"
      :fields="fields"
      @row-clicked="(item) => checked(item)"
    >
      <template #select="{ item }">
        <be-form-group
          :label="
            $t(
              'components.meetings.material.minutes.comments.select_to_request'
            )
          "
          label-sr-only
          class="m-0"
        >
          <be-form-checkbox
            v-model="item.selected"
            @change="(value) => checked(item, value)"
          />
        </be-form-group>
      </template>

      <template #avatar="{ item }">
        <user-avatar v-if="item.user_id" :user="item.user_id" />
      </template>

      <template #name="{ item }">
        <div>
          {{ item.name }}
        </div>

        <div v-if="forReview" class="small">
          {{ getTitle(item) }}
        </div>

        <membership-subtitle v-else :user-id="item.user_id" class="small" />
      </template>

      <template #email="{ item }">
        {{ getUserEmail(item.user_id) }}
      </template>

      <template #sent_at="{ item }">
        <be-badge v-if="lastRequestDate(item)" variant="success">
          {{ lastRequestDate(item) }}
        </be-badge>
      </template>
    </be-table>

    <be-button
      size="sm"
      variant="outline-secondary"
      @click="checkAll(!allIsChecked)"
    >
      {{ toggleAllButtonText }}
    </be-button>

    <be-alert v-if="noneSelected" show variant="warning" class="mt-2">
      {{
        $t("components.meetings.material.minutes.comments.no_receivers_warning")
      }}
    </be-alert>

    <template #footer>
      <be-button variant="light" @click="onCancel">
        {{ $t("buttons.titles.close") }}
      </be-button>

      <be-button
        v-be-tooltip="{
          title: $t(
            'components.meetings.material.minutes.comments.send_disabled'
          ),
          disabled: !noneSelected,
        }"
        variant="primary"
        :disabled="noneSelected"
        :loading="processing"
        icon="fa-envelope"
        @click="onSend"
      >
        {{ $t("modals.send") }}
      </be-button>
    </template>
  </be-modal>
</template>

<script>
import { mapGetters } from "vuex";

import materialState from "@/components/meetings/material/materialState";
import MembershipSubtitle from "@/components/shared/MembershipSubtitle.vue";
import RequestHandler from "@/mixins/RequestHandler";

export default {
  components: {
    MembershipSubtitle,
  },

  mixins: [materialState, RequestHandler],

  props: {
    id: {
      type: String,
      default: "comments-request-modal",
    },

    attendances: {
      type: Array,
      required: true,
    },

    commentsRequests: {
      type: Array,
      required: true,
    },

    sendReview: {
      type: Boolean,
      required: true,
    },

    mode: {
      type: String,
      required: false,
      default: "",
    },
  },

  emits: ["sent"],

  data() {
    return {
      processing: false,
      informBoardMembers: false,
      sendForSigningOnceReviewed: true,
      message: "",
      dueAt: "",
      selected: {},
    };
  },

  computed: {
    ...mapGetters({
      meeting: "material/meeting",
    }),

    allIsChecked() {
      return (
        Object.values(this.selected).filter((s) => s).length ==
        this.attendancesAndSelections.length
      );
    },

    attendancesAndSelections() {
      let attendances = this.attendances;
      if (this.mode == "review") {
        attendances = attendances.filter((attendance) => attendance.reviewer);
      }

      return attendances.map((attendance) => {
        return {
          ...attendance,
          selected: this.selected[attendance.id] == true,
        };
      });
    },

    toggleAllButtonText() {
      return this.allIsChecked
        ? this.$t("components.meetings.material.minutes.comments.mark_none")
        : this.$t("components.meetings.material.minutes.comments.mark_all");
    },

    forReview() {
      return this.sendReview || this.mode == "review";
    },

    modalTitle() {
      return this.$t(
        this.forReview
          ? "components.meetings.material.minutes.comments.comments_due_at_for_review_title"
          : "components.meetings.material.minutes.comments.comments_due_at_title"
      );
    },

    selectedAttendances() {
      return this.attendances.filter(
        (attendance) => this.selected[attendance.id] == true
      );
    },

    noneSelected() {
      return !Object.values(this.selected).some((value) => value);
    },

    fields() {
      return [
        {
          key: "select",

          label: this.$t(
            "components.meetings.material.minutes.comments.send_to"
          ),

          class: "col-shrink text-center",
        },
        { key: "avatar", label: "", class: "col-shrink text-center" },
        {
          key: "name",
          label: this.translateAttribute("user", "name"),
        },
        {
          key: "email",
          label: this.translateAttribute("user", "email"),
        },
        {
          key: "sent_at",

          label: this.$t(
            "components.meetings.material.minutes.comments.sent_at"
          ),

          class: "col-shrink",
        },
      ];
    },

    currentCommentsRequests() {
      if (this.mode === "comments") {
        return this.commentsRequests.filter((request) => request.pre_review);
      } else {
        return this.commentsRequests.filter((request) => !request.pre_review);
      }
    },

    commentsRequestReceiversByAttendance() {
      const data = {};
      this.currentCommentsRequests.forEach((request) =>
        request.comments_request_receivers.map((receiver) => {
          data[receiver.attendance_id] = {
            ...receiver,
            requested_at: request.created_at,
            due_at: request.due_at,
            pre_review: request.pre_review,
            fallback_reminder_sent_at: request.reminded_at,
          };
        })
      );
      return data;
    },

    sendForSigningOnceReviewedOptions() {
      return [
        {
          value: true,

          text: this.$t(
            "components.meetings.material.minutes.comments.send_for_signing_once_reviewed"
          ),
        },
        {
          value: false,

          text: this.$t(
            "components.meetings.material.minutes.comments.approve_minutes_between_review_and_sign"
          ),
        },
      ];
    },
  },

  methods: {
    async onSend() {
      if (this.forReview && !this.minutesReviewStarted) {
        const isConfirmed = await this.promptConfirm(
          this.$t("models.material.minutes.confirm_send_to_reviewer")
        );

        if (!isConfirmed) {
          this.onCancel();
          return;
        }
      }

      this.processing = true;

      try {
        const response = await axios.post(
          `${this.meeting.paths.base}/minutes/comments_request`,
          {
            comments_request: {
              message: this.message,
              due_at: this.dueAt,

              attendance_ids: this.selectedAttendances.map(
                (attendance) => attendance.id
              ),

              pre_review: this.mode == "comments",
            },

            minutes: {
              secretary_done: this.sendReview,
            },

            meeting: {
              inform_board_members_of_minutes_review_completion:
                this.informBoardMembers,

              send_for_signing_once_reviewed: this.sendForSigningOnceReviewed,
            },
          }
        );

        this.$beModal.hide(this.id);
        this.resetForm();

        this.$store.commit("meetings/updateCommentsRequestForMeeting", {
          meetingId: this.meeting.id,
          commentsRequest: response.data,
        });

        this.$emit("sent");
      } catch (error) {
        this.handleError(error);
      } finally {
        this.processing = false;
      }
    },

    onCancel() {
      this.$beModal.hide(this.id);
      this.resetForm();
    },

    resetForm() {
      this.informBoardMembers =
        this.meeting.inform_board_members_of_minutes_review_completion;
      this.message = "";
      this.processing = false;
      this.message = "";
      this.dueAt = "";
      this.selected = {};
      if (this.forReview) {
        this.attendances.forEach((attendance) => {
          if (attendance.reviewer) {
            this.selected[attendance.id] = true;
          }
        });
      }
    },

    getTitle(attendance) {
      if (attendance.reviewer) {
        return this.$t("activerecord.attributes.attendance.reviewer");
      }
      if (attendance.secretary) {
        return this.$t("activerecord.attributes.attendance.secretary");
      }

      return "";
    },

    getNextMeetingDate() {
      return this.meeting.next_start_at || "";
    },

    lastRequestDate(attendance) {
      const result = this.commentsRequestReceiversByAttendance[attendance.id];

      if (result) {
        return this.$d(new Date(result.requested_at), "humanReadable");
      }
      return null;
    },

    checked(attendance, value) {
      let checkedValue = value;
      if (checkedValue === null || checkedValue === undefined) {
        checkedValue = !this.selected[attendance.id];
      }
      this.selected[attendance.id] = checkedValue;
    },

    checkAll(value = true) {
      this.attendancesAndSelections.forEach((attendance) => {
        this.selected[attendance.id] = value;
      });
    },

    isReviewerOrSecretary(attendance) {
      return attendance.reviewer || attendance.secretary;
    },

    setMessage(content) {
      this.message = content;
    },
  },
};
</script>
