<template>
  <div class="table-responsive">
    <table v-if="activeAttendances.length > 0" class="table table-hover">
      <thead>
        <tr>
          <th class="col-shrink"></th>

          <th>
            {{ $t("activerecord.attributes.user.name") }}
          </th>

          <th></th>

          <th>
            {{ $t("attributes.status") }}
          </th>

          <th class="col-shrink"></th>
        </tr>
      </thead>

      <tbody>
        <tr
          v-for="attendance in activeAttendances"
          :key="commentAttendanceKey(attendance)"
        >
          <td>
            <user-avatar :user="attendance.user_id" />
          </td>

          <td>
            {{ attendance.name }}
            <div class="small">{{ attendanceRole(attendance) }}</div>
          </td>

          <td class="col-shrink pr-0">
            <template v-if="isReviewer(attendance)">
              <template v-if="attendance.done_commenting_at">
                <i
                  v-if="reviewLevelClasses(attendance.review_level)"
                  class="mr-1 fa-fw"
                  :class="reviewLevelClasses(attendance.review_level)"
                />
              </template>

              <template v-else-if="reviewRequestedAt(attendance) != null">
                <i
                  v-if="attendance.review_level === 'pending'"
                  class="mr-1 invisible fa-fw fal fa-times"
                />
              </template>
            </template>

            <template v-else-if="attendance.commented_at">
              <i class="text-muted mr-1 fa-fw fal fa-comment" />
            </template>

            <template v-else>
              <i class="invisible mr-1 fa-fw fal fa-comment" />
            </template>
          </td>

          <td class="pl-2">
            <template v-if="isReviewer(attendance)">
              <template v-if="attendance.done_commenting_at">
                {{
                  $t(
                    `models.attendance.review_levels.${attendance.review_level}`
                  )
                }}
                <div class="small text-muted">
                  {{ $d(parseDate(attendance.done_commenting_at), "dateTime") }}
                </div>
              </template>

              <template v-else-if="reviewRequestedAt(attendance) != null">
                {{ $t("models.attendance.review_requested") }}

                <div class="small text-muted">
                  {{ reviewRequestedAt(attendance) }}
                  <template v-if="commentsDueAt(attendance)">
                    -
                    {{
                      $t(
                        "components.meetings.tabs.components.meeting_comments.due_at_w_date",
                        {
                          date: commentsDueAt(attendance),
                        }
                      )
                    }}
                  </template>
                </div>
              </template>
            </template>

            <template v-else-if="attendance.done_commenting_at">
              {{ $t("models.attendance.is_done_commenting") }}

              <div class="small text-muted">
                {{ $d(parseDate(attendance.done_commenting_at), "dateTime") }}
              </div>
            </template>

            <template v-else-if="attendance.commented_at">
              {{ $t("models.attendance.has_commented") }}

              <div class="small text-muted">
                {{ $d(parseDate(attendance.commented_at), "dateTime") }}
              </div>
            </template>

            <template v-else>
              {{ $t("models.attendance.comments_requested") }}

              <div class="small text-muted">
                {{ commentsRequestedAt(attendance) }}
                <template v-if="commentsDueAt(attendance)">
                  -
                  {{
                    $t(
                      "components.meetings.tabs.components.meeting_comments.due_at_w_date",
                      {
                        date: commentsDueAt(attendance),
                      }
                    )
                  }}
                </template>
              </div>
            </template>
          </td>

          <td class="col-shrink text-right">
            <template v-if="!minutes.sealed">
              <template v-if="canRemind(attendance)">
                <be-button
                  v-be-tooltip="{
                    title: remindedAtMessage(attendance),
                    disabled: !remindedAtMessage(attendance),
                  }"
                  size="sm"
                  variant="outline-secondary"
                  :disabled="isReminded(attendance)"
                  icon="fa-envelope"
                  @click="sendReminder(attendance)"
                >
                  {{
                    remindedAtMessage(attendance)
                      ? $t("buttons.titles.remind_again")
                      : $t("buttons.titles.remind")
                  }}
                </be-button>
              </template>

              <template
                v-if="
                  mode === 'review-tab' &&
                  attendance.user_id !== $currentUser.id &&
                  attendance.user_id &&
                  isReviewer(attendance) &&
                  reviewRequestedAt(attendance) == null
                "
              >
                <be-button
                  size="sm"
                  variant="outline-primary"
                  icon="fa-envelope"
                  @click="sendReminder(attendance)"
                >
                  {{ $t("components.meetings.tabs.review.request_review") }}
                </be-button>
              </template>

              <template
                v-else-if="
                  attendance.user_id === $currentUser.id &&
                  commentButtonText(attendance)
                "
              >
                <be-button
                  size="sm"
                  variant="outline-primary"
                  :href="`${meeting.paths.base}/minutes`"
                >
                  {{ commentButtonText(attendance) }}
                </be-button>
              </template>
            </template>
          </td>
        </tr>
      </tbody>
    </table>

    <be-alert v-else-if="noAttendancesMessage" variant="info">
      {{ noAttendancesMessage }}
    </be-alert>
  </div>
</template>
<script>
import { isAfter, subDays } from "date-fns";
import MaterialMixin from "@/mixins/meetings/material";
import sortBy from "lodash/sortBy";

export default {
  mixins: [MaterialMixin],

  props: {
    meeting: {
      type: Object,
      required: true,
    },

    minutes: {
      type: Object,
      required: true,
    },

    attendances: {
      type: Array,
      required: true,
    },

    commentsRequests: {
      type: Array,
      required: true,
    },

    mode: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      reminded: [],
    };
  },

  computed: {
    secretaryAttendanceId() {
      let secretaryAttendance = this.attendances.find(
        (attendance) => attendance.secretary
      );
      if (!secretaryAttendance) {
        return false;
      }

      return secretaryAttendance.id;
    },

    // Index all comments_request_receivers by attendance_id
    commentsRequestReceiversByAttendance() {
      const data = {};
      this.commentsRequests.forEach((request) =>
        request.comments_request_receivers.map((receiver) => {
          data[receiver.attendance_id] = {
            ...receiver,
            requested_at: request.created_at,
            due_at: request.due_at,
            pre_review: request.pre_review,
            fallback_reminder_sent_at: request.reminded_at,
          };
        })
      );
      return data;
    },

    inReview() {
      return this.minutes.secretary_done_at && !this.minutes.sealed;
    },

    activeAttendances() {
      if (this.mode === "review-tab") {
        const attendances = this.attendances.filter(
          (attendance) =>
            attendance.reviewer ||
            attendance.commenter ||
            attendance.commented_at
        );
        return sortBy([...attendances], (attendance) => {
          if (attendance.reviewer) {
            return 1;
          } else if (attendance.commenter) {
            return 2;
          } else if (attendance.commented_at) {
            return 3;
          } else {
            return 4;
          }
        });
      } else if (this.mode == "minutes-tab") {
        return this.attendances.filter((attendance) => {
          return (
            this.commentsRequestReceiversByAttendance[attendance.id]
              ?.pre_review || attendance.commented_at
          );
        });
      } else if (this.mode == "review-page-comments") {
        return this.attendances.filter((attendance) => {
          if (this.inReview && attendance.reviewer) {
            return false;
          }

          return (
            this.commentsRequestReceiversByAttendance[attendance.id]
              ?.pre_review || attendance.commented_at
          );
        });
      } else if (this.mode === "review-page-reviews") {
        return this.attendances.filter((attendance) => attendance.reviewer);
      } else {
        return this.attendances;
      }
    },

    noAttendancesMessage() {
      if (this.mode === "review-page-comments") {
        return this.$t(
          "components.meetings.tabs.components.meeting_comments.no_comments_requested"
        );
      } else if (this.mode === "review-page-reviews") {
        return this.$t(
          "components.meetings.tabs.components.meeting_comments.no_reviewers_available"
        );
      }
      return null;
    },
  },

  methods: {
    isReminded(attendance) {
      return this.reminded.some((reminded) => reminded === attendance.id);
    },

    commentAttendanceKey(attendance) {
      if (attendance.id) {
        return `comments-attendance-${attendance.id}`;
      }
      return `comments-attendance-user-${attendance.user_id}`;
    },

    async sendReminder(attendance) {
      try {
        await axios.post(
          `${this.meeting.paths.base}/reviewers/${attendance.id}/reminder`
        );

        this.reminded.push(attendance.id);
        // Reload all comments_requests to show reminded_at
        this.fetchCommentsRequestsForMeeting(this.meeting, { reload: true });
      } catch (error) {
        this.handleError(error);
      }
    },

    isCurrentUser(attendance) {
      return this.$currentUser.id === attendance.user_id;
    },

    actionButtonText({ done_commenting_at }) {
      if (this.reviewMode && !done_commenting_at) {
        return this.$t(
          "companies.dashboard.tabs.modules.comments_table.review"
        );
      } else if (!done_commenting_at) {
        return this.$t(
          "companies.dashboard.tabs.modules.comments_table.comments"
        );
      } else {
        return this.$t("companies.dashboard.tabs.modules.comments_table.show");
      }
    },

    canRemind(attendance) {
      const receiver = this.commentsRequestReceiversByAttendance[attendance.id];

      if (!receiver) return false;

      if (
        attendance.user_id == null ||
        attendance.user_id === this.$currentUser.id
      )
        return false;

      if (this.minutes?.policy?.remind_reviewer === false) return false;

      if (this.minutes.sealed) return false;

      if (this.mode === "minutes-tab") return receiver.pre_review;

      return !attendance.done_commenting_at && !receiver.pre_review;
    },

    reviewMode() {
      return this.mode === "review-tab" || this.mode === "review-page-reviews";
    },

    isReviewer(attendance) {
      return this.reviewMode && attendance.reviewer;
    },

    commentsRequestedAt(attendance) {
      const receiver = this.commentsRequestReceiversByAttendance[attendance.id];
      if (receiver) {
        return this.$d(this.parseDate(receiver.created_at), "date");
      }
    },

    commentsDueAt(attendance) {
      const comments_request =
        this.commentsRequestReceiversByAttendance[attendance.id];
      const due_at = comments_request?.due_at || this.minutes.comments_due_at;

      if (!due_at) {
        return null;
      }

      try {
        return this.$d(this.parseDate(due_at), "date");
      } catch {
        return null;
      }
    },

    reviewRequestedAt(attendance) {
      const comments_request =
        this.commentsRequestReceiversByAttendance[attendance.id];

      if (comments_request && !comments_request.pre_review) {
        return this.$d(this.parseDate(comments_request.created_at), "date");
      }

      return null;
    },

    remindedAtMessage(attendance) {
      const receiver = this.commentsRequestReceiversByAttendance[attendance.id];
      const remindedAt =
        receiver?.reminded_at || receiver?.fallback_reminder_sent_at;
      if (remindedAt) {
        const date = this.$i18n.d(
          this.parseDate(remindedAt),
          "humanReadableWithTime"
        );
        return this.$t("models.comments_request.reminder_sent_at_w_date", {
          date: date,
        });
      }

      return null;
    },

    showDueDateWarning(dateString) {
      if (!dateString) {
        return;
      }

      const dueAt = this.parseDate(dateString);
      const thresholdDate = subDays(new Date(), 14);

      if (dueAt) {
        return isAfter(dueAt, thresholdDate);
      }
      return false;
    },

    commentButtonText(attendance) {
      const comments_request =
        this.commentsRequestReceiversByAttendance[attendance.id];

      if (!comments_request) {
        return;
      }

      if (
        this.mode === "minutes-tab" &&
        attendance.commenter &&
        comments_request.pre_review
      ) {
        return this.$t("models.attendance.comment");
      }

      if (!comments_request.pre_review) {
        return this.$t("models.attendance.review");
      }
    },
  },
};
</script>
