<template>
  <div>
    <be-tabs
      v-model="activeTab"
      class="d-print-none"
      :options="tabsData"
      translation-scope="components.shareholder_registers.shareholder_register_tabs"
    >
      <template #content-right>
        <div
          v-if="
            shareholderRegister &&
            (shareholderRegister.policy.update || canExportRegister)
          "
        >
          <div
            v-if="shareholderRegister.policy.update"
            class="mb-2 mb-md-0 d-md-inline mt-3 mt-md-0"
          >
            <new-action-button />
          </div>

          <a
            v-if="shareholderRegister.policy.add_owner"
            class="btn btn-outline-secondary btn-block btn-md-inline-block mb-2 mb-md-0 text-nowrap"
            :href="url(`/shareholder_register/edit`)"
          >
            {{ $t("models.shareholder.new") }}
          </a>

          <be-dropdown v-if="canExportRegister" ellipsis>
            <be-dropdown-item v-be-modal.export-shareholder-register>
              {{ exportModalTitle }}
            </be-dropdown-item>
          </be-dropdown>

          <export-modal-with-filters
            id="export-shareholder-register"
            :export-types="[
              'shareholder_register_pdf',
              'shareholder_register_excel',
            ]"
            :filter-types="['shareholders', 'cap_table', 'transaction_history']"
            :title="exportModalTitle"
            :preselected-options="exportPreselectedOptions"
          />
        </div>

        <div v-else>
          <a
            v-if="
              shareholderRegister &&
              !shareholderRegister.legal_types_present &&
              shareholderRegister.policy.add_owner
            "
            class="btn btn-outline-secondary"
            :href="url('/shareholder_register')"
          >
            {{ $t("models.shareholder.new") }}
          </a>
        </div>
      </template>
    </be-tabs>

    <div class="card">
      <div class="card-body">
        <be-alert
          v-if="
            shareCountMismatches &&
            activeTab != tabsData.companyInformation.value
          "
          variant="danger"
          class="d-print-none"
        >
          {{
            $t(
              "components.shareholder_registers.shareholder_list.total_share_count_not_matching"
            )
          }}
        </be-alert>

        <div
          v-if="activeTab == tabsData.shareholderList.value"
          id="shareholder-list"
          class="tab-pane show active"
          role="tabpanel"
        >
          <shareholder-list v-if="showShareholderList" />

          <main-introduction v-else :processing-started="processingStarted" />
        </div>

        <div
          v-if="activeTab == tabsData.shareholderRegister.value"
          id="shareholder-register"
          class="tab-pane"
          role="tabpanel"
        >
          <shareholder-register />
        </div>

        <div
          v-if="activeTab == tabsData.capTable.value"
          id="cap-table"
          class="tab-pane"
          role="tabpanel"
        >
          <cap-table />
        </div>

        <div
          v-if="activeTab == tabsData.transactionHistory.value"
          id="transaction-history"
          class="tab-pane"
          role="tabpanel"
        >
          <transaction-history />
        </div>

        <div
          v-if="activeTab == tabsData.companyInformation.value"
          id="company-information"
          class="tab-pane"
          role="tabpanel"
        >
          <company-information />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import CapTable from "./CapTable.vue";
import CompanyInformation from "./CompanyInformation.vue";
import MainIntroduction from "./MainIntroduction.vue";
import ShareholderList from "./ShareholderList.vue";
import ShareholderRegister from "./ShareholderRegister.vue";
import TransactionHistory from "./transactions/TransactionHistory.vue";
import NewActionButton from "./NewActionButton.vue";

import ShareholderRegisterMixins from "@/mixins/shareholderRegisters";

const TABS = {
  shareholderList: "shareholder-list",
  shareholderRegister: "shareholder-register",
  capTable: "cap-table",
  transactionHistory: "transaction-history",
  companyInformation: "company-information",
};

export default {
  components: {
    CapTable,
    CompanyInformation,
    MainIntroduction,
    ShareholderList,
    ShareholderRegister,
    TransactionHistory,
    NewActionButton,
  },

  mixins: [ShareholderRegisterMixins],

  props: {
    processingStarted: {
      type: Boolean,
      required: false,
      default: false,
    },

    shareholderRegister: {
      type: Object,
      required: true,
    },

    initialTab: {
      type: String,
      required: true,
    },

    transactions: {
      type: Array,
      required: false,
      default: () => [],
    },
  },

  data() {
    const tabsData = {};
    Object.entries(TABS).map(([key, value]) => {
      tabsData[key] = { value };
    });

    return {
      activeTab: this.initialTab,
      tabsData,
    };
  },

  computed: {
    ...mapGetters({
      shareholders: "shareholder_register/currentShareholders",
    }),

    canExportRegister() {
      return (
        this.transactions.length > 0 && this.shareholderRegister.policy.export
      );
    },

    exportModalTitle() {
      return this.$t("buttons.titles.export_w_title", {
        title: this.$t(
          "components.shareholder_registers.export.shareholders_cap_table_transaction_history"
        ).toLowerCase(),
      });
    },

    exportPreselectedOptions() {
      return {
        exportType: "shareholder_register_pdf",
        filterType: this.getFilterTypeFromTab(),
      };
    },

    showShareholderList() {
      let previousRegisterAction =
        this.shareholderRegister.shareholder_register_actions.find((action) => {
          return action.action_type == "previous_register";
        });

      let onlyPreviousRegisterAction =
        this.shareholderRegister.shareholder_register_actions.length == 1 &&
        previousRegisterAction;

      return (
        this.shareholderRegister &&
        this.shareholderRegister.policy.show &&
        this.shareholderRegister.shareholder_register_actions.length > 0 &&
        !onlyPreviousRegisterAction
      );
    },

    shareCountMismatches() {
      for (const shareholder of this.shareholders) {
        if (this.shareCountMismatch(shareholder)) {
          return true;
        }
      }

      return false;
    },
  },

  methods: {
    getFilterTypeFromTab() {
      switch (this.activeTab) {
        case "shareholder-list":
          return "shareholders";
        case "cap-table":
          return "cap_table";
        case "transaction-history":
          return "transaction_history";
        default:
          return "shareholders";
      }
    },
  },
};
</script>
