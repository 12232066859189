<template>
  <div class="tab-content">
    <be-tabs
      v-model="activeTab"
      :options="tabOptions"
      translation-scope="components.annual_reports.tabs"
    >
      <template #content-right>
        <be-button
          v-if="allowedToCreate"
          v-be-modal.new-annual-report
          variant="outline-primary"
        >
          {{ $t("models.annual_report.new") }}
        </be-button>
      </template>
    </be-tabs>

    <new-annual-report-form />

    <section v-if="activeTab == 'active'" id="active">
      <div v-if="activeAnnualReports.length > 0">
        <annual-report
          v-for="annualReport in activeAnnualReports"
          :key="`annual-report-${annualReport.id}`"
          :annual-report-id="annualReport.id"
        />
      </div>

      <div v-else class="card">
        <div class="card-body">
          <div class="row">
            <div class="col-12 col-md-6 order-2 order-md-1">
              <p>
                {{ $t("components.annual_reports.introduction.description_1") }}
                <template v-if="organizationType === 'foundation'">
                  {{
                    $t(
                      "components.annual_reports.introduction.description_1_foundation"
                    )
                  }}
                </template>

                <template v-else>
                  {{
                    $t(
                      "components.annual_reports.introduction.description_1_limited_company"
                    )
                  }}
                </template>
              </p>

              <p v-if="organizationType !== 'foundation'" class="mb-3">
                {{ $t("components.annual_reports.introduction.description_2") }}
              </p>

              <be-button
                v-if="allowedToCreate && annualReports.length === 0"
                v-be-modal.new-annual-report
                variant="primary"
                class="mb-3"
              >
                {{ $t("models.annual_report.new") }}
              </be-button>

              <be-alert
                v-if="
                  !['limited_company', 'foundation'].includes(organizationType)
                "
                variant="info"
              >
                {{
                  $t(
                    "components.annual_reports.introduction.limited_company_disclaimer"
                  )
                }}
              </be-alert>
            </div>

            <div class="col-12 col-md-6 order-1 order-md-2 mb-3 mb-md-0">
              <be-img :src="imageSrc('onboarding/intro/annual_reports.png')" />
            </div>
          </div>
        </div>
      </div>
    </section>

    <section v-if="activeTab == 'archived'" id="archived">
      <div v-if="archivedAnnualReports.length > 0">
        <annual-report
          v-for="annualReport in archivedAnnualReports"
          :key="`annual-report-${annualReport.id}`"
          :annual-report-id="annualReport.id"
        />
      </div>

      <div v-else class="card">
        <div class="card-body">
          <be-alert variant="info">
            {{ $t("components.annual_reports.introduction.no_archived") }}
          </be-alert>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
import AnnualReport from "./AnnualReport.vue";

const {
  mapGetters: mapAnnualReportGetters,
  mapActions: mapAnnualReportActions,
} = createNamespacedHelpers("annual_reports");
const { mapGetters: mapCompanyGetters } = createNamespacedHelpers("company");

export default {
  components: { AnnualReport },

  provide() {
    return {
      subfoundations: this.subfoundations,
    };
  },

  props: {
    annualReports: {
      type: Array,
      required: true,
    },

    allowedToCreate: {
      type: Boolean,
      required: false,
      default: false,
    },

    subfoundations: {
      type: Array,
      required: false,
      default: () => [],
    },
  },

  data() {
    return {
      activeTab: "active",
    };
  },

  computed: {
    ...mapAnnualReportGetters(["activeAnnualReports", "archivedAnnualReports"]),

    ...mapCompanyGetters({
      hasActiveFeature: "hasActiveFeature",
      organizationType: "getOrganizationType",
    }),

    tabOptions() {
      return {
        active: {
          value: "active",
          count: this.activeAnnualReports.length,
        },

        archived: {
          value: "archived",
          count: this.archivedAnnualReports.length,
        },
      };
    },
  },

  watch: {
    activeAnnualReports: {
      handler(value, oldValue) {
        if (value.length == 0 && oldValue.length != 0) {
          this.activeTab = "archived";
        }
      },

      deep: true,
    },
  },

  created() {
    this.setAnnualReports(this.annualReports);
    if (
      this.activeAnnualReports.length == 0 &&
      this.archivedAnnualReports.length > 0
    ) {
      this.activeTab = "archived";
    }
  },

  methods: {
    ...mapAnnualReportActions(["setAnnualReports"]),
  },
};
</script>
