<template>
  <be-button
    v-if="previousUuid"
    v-be-tooltip="tooltip"
    size="sm"
    icon="fa-arrows-rotate"
    class="d-inline-block"
    :disabled="disabled"
    :loading="loading || fetching"
    @click="fetchDocument"
  />
</template>

<script>
import { mapActions, mapMutations } from "vuex";

export default {
  props: {
    annualReport: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      failed: false,
      loading: true,
      fetching: false,
      remoteDocument: null,
    };
  },

  computed: {
    previousUuid() {
      return this.annualReport.annual_report_ixbrl_source_uuid;
    },

    disabled() {
      return !this.remoteDocument;
    },

    tooltip() {
      if (this.loading || this.fetching) {
        return false;
      }

      if (this.disabled) {
        return this.$t(
          "components.annual_reports.visualby_annual_report_refetcher.no_document"
        );
      }

      return this.$t("buttons.titles.refresh");
    },
  },

  async mounted() {
    if (!this.previousUuid) {
      this.loading = false;
      return;
    }

    try {
      const { data } = await axios.get(
        this.url(`/annual_reports/${this.annualReport.id}/visualby_document`)
      );

      this.remoteDocument = data.items.find(
        ({ uuid, lockedTime }) => uuid == this.previousUuid && lockedTime
      );
    } catch {
      this.failed = true;
    } finally {
      this.loading = false;
    }
  },

  methods: {
    ...mapActions({ removeDocument: "annual_reports/removeDocument" }),
    ...mapMutations({ setAnnualReport: "annual_reports/setAnnualReport" }),

    async fetchDocument() {
      this.fetching = true;

      const remoteDocument = this.remoteDocument;
      const type = this.formatType(remoteDocument.type);
      const time = this.formatDate(remoteDocument.lockedTime);
      const filename = `${type}_${time}.ixbrl`;

      try {
        await this.removeDocument({
          annualReport: this.annualReport,
          document: this.annualReport.annual_report_ixbrl_document,
        });

        const { data } = await axios.post(
          this.url(`/annual_reports/${this.annualReport.id}/visualby_document`),
          {
            key: "annual_report",
            uuid: this.previousUuid,
            filename,
          }
        );
        this.setAnnualReport(data.annual_report);
        this.fetching = false;
      } catch {
        // There will be a flash message, and we don't need to do anything else
        this.fetching = false;
      }
    },

    formatDate(dateString) {
      if (dateString) {
        return this.$d(this.parseDate(dateString), "dateTime");
      } else {
        return "-";
      }
    },

    formatType(typeString) {
      if (typeString.startsWith("K3_GROUP")) {
        return this.$t(
          "components.annual_reports.visualby_annual_report_fetcher.k3_group"
        );
      }
      return this.$t(
        "components.annual_reports.visualby_annual_report_fetcher.unknown_type"
      );
    },
  },
};
</script>
