<template>
  <div>
    <!-- Dates -->
    <div class="row">
      <div class="col-12 col-md-6">
        <be-form-group
          label-for="start-at"
          :label="
            $t('activerecord.attributes.company_group_ownership_state.start_at')
          "
          :description="
            states.length > 0
              ? $t('models.company_group_ownership_state.dates_can_be_blocked')
              : ''
          "
          :error="getErrors(ownershipErrors, 'start_at')"
          required
        >
          <be-form-datepicker
            id="start-at"
            v-model="ownershipState.start_at"
            :name="`${namePrefix}[start_at]`"
            :disabled-dates="dateDisabled"
            :state="validationState(ownershipErrors, 'start_at')"
            :clearable="false"
            @change="clearErrors(ownershipErrors, 'start_at')"
            @input="validateDate('start_at')"
            @open="currentDatePicker = 'start_at'"
          />
        </be-form-group>
      </div>

      <div class="col-12 col-md-6">
        <be-form-group
          label-for="end-at"
          :label="
            $t('activerecord.attributes.company_group_ownership_state.end_at')
          "
          :description="
            states.length > 0
              ? $t('models.company_group_ownership_state.dates_can_be_blocked')
              : ''
          "
          :error="getErrors(ownershipErrors, 'end_at')"
        >
          <be-form-datepicker
            id="end-at"
            v-model="ownershipState.end_at"
            :name="`${namePrefix}[end_at]`"
            :disabled-dates="dateDisabled"
            :state="validationState(ownershipErrors, 'end_at')"
            :clearable="isMostRecentState"
            @change="clearErrors(ownershipErrors, 'end_at')"
            @input="validateDate('end_at')"
            @open="currentDatePicker = 'end_at'"
          />
        </be-form-group>
      </div>
    </div>

    <!-- Shares -->
    <div class="row">
      <div class="col-12 col-md-6 col-lg-4">
        <be-form-group
          label-for="shares"
          :label="$t('models.company_group_ownership_state.total_shares')"
          :error="getErrors(ownershipErrors, 'shares')"
        >
          <be-form-input
            id="shares"
            v-model="ownershipState.shares"
            type="number"
            :name="`${namePrefix}[shares]`"
            required
            @change="clearErrors(ownershipErrors, 'shares')"
          />
        </be-form-group>
      </div>

      <div class="col-12 col-md-6 col-lg-4">
        <be-form-group
          label-for="owned-shares"
          :label="$t('models.company_group_ownership_state.total_owned_shares')"
          :error="getErrors(ownershipErrors, 'owned_shares')"
        >
          <be-form-input
            id="owned-shares"
            v-model="ownershipState.owned_shares"
            type="number"
            :name="`${namePrefix}[owned_shares]`"
            required
            @change="clearErrors(ownershipErrors, 'owned_shares')"
          />
        </be-form-group>
      </div>

      <div class="col-12 col-md-6 col-lg-4">
        <label>
          {{
            $t("models.company_group_ownership_state.owned_share_percentage")
          }}
        </label>

        <p class="m-0 mt-md-2 mb-2">{{ ownedSharesPercentage }}%</p>
      </div>
    </div>

    <!-- Votes -->
    <div class="row">
      <div class="col-12 col-md-6 col-lg-4">
        <be-form-group
          label-for="votes"
          :label="$t('models.company_group_ownership_state.total_votes')"
          :error="getErrors(ownershipErrors, 'votes')"
        >
          <be-form-input
            id="votes"
            v-model="ownershipState.votes"
            type="number"
            :name="`${namePrefix}[votes]`"
            required
            @change="clearErrors(ownershipErrors, 'votes')"
          />
        </be-form-group>
      </div>

      <div class="col-12 col-md-6 col-lg-4">
        <be-form-group
          label-for="owned-votes"
          :label="$t('models.company_group_ownership_state.total_owned_votes')"
          :error="getErrors(ownershipErrors, 'owned_votes')"
        >
          <be-form-input
            id="owned-votes"
            v-model="ownershipState.owned_votes"
            type="number"
            :name="`${namePrefix}[owned_votes]`"
            required
            @change="clearErrors(ownershipErrors, 'owned_votes')"
          />
        </be-form-group>
      </div>

      <div class="col-12 col-md-6 col-lg-4">
        <label>
          {{
            $t("models.company_group_ownership_state.owned_voting_percentage")
          }}
        </label>

        <p class="m-0 mt-md-2 mb-2">{{ ownedVotePercentage }}%</p>
      </div>
    </div>

    <!-- Type -->
    <div class="row">
      <div class="col-12 col-md-auto">
        <be-form-group
          label-for="type"
          :label="
            $t(
              'activerecord.attributes.company_group_ownership_state.ownership_type'
            )
          "
        >
          <be-form-select
            id="type"
            v-model="type"
            :options="typeOptions"
            required
          />

          <input
            v-model="ownershipState.ownership_type_mode"
            type="hidden"
            class="hidden"
            :name="`${namePrefix}[ownership_type_mode]`"
          />

          <input
            v-model="ownershipState.ownership_type"
            type="hidden"
            class="hidden"
            :name="`${namePrefix}[ownership_type]`"
          />
        </be-form-group>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    modelValue: {
      type: Object,
      required: false,

      default: () => ({
        start_at: null,
        end_at: null,
        shares: null,
        owned_shares: null,
        votes: null,
        owned_votes: null,
        ownership_type_mode: "auto",
        ownership_type: "daughter",
      }),
    },

    namePrefix: {
      type: String,
      required: false,
      default: "",
    },

    states: {
      type: Array,
      required: false,
      default: () => [],
    },

    errors: {
      type: Object,
      required: false,
      default: () => ({}),
    },
  },

  emits: ["update:modelValue"],

  data() {
    return {
      currentDatePicker: null,
      ownershipState: { ...this.modelValue },
    };
  },

  computed: {
    isMostRecentState() {
      const lastState = this.states[this.states.length - 1];
      return lastState?.id === this.ownershipState.id;
    },

    ownershipErrors() {
      if (Object.keys(this.errors).length > 0) {
        return { errors: this.errors };
      } else {
        return this.ownershipState;
      }
    },

    ownedSharesPercentage() {
      return this.calculatePercentage(
        this.ownershipState.shares,
        this.ownershipState.owned_shares
      );
    },

    ownedVotePercentage() {
      return this.calculatePercentage(
        this.ownershipState.votes,
        this.ownershipState.owned_votes
      );
    },

    automaticType() {
      // TODO: Add enum from backend
      if (this.ownedVotePercentage > 50) {
        return "daughter";
      } else if (this.ownedVotePercentage > 20) {
        return "interest";
      } else {
        return "investment";
      }
    },

    typeOptions() {
      return [
        {
          value: "AUTO",

          text: this.$t(
            "models.company_group_ownership_state.type_from_voting_length",
            {
              type: this.$t(
                `models.company_group_ownership_state.ownership_type.${this.automaticType}`
              ),
            }
          ),
        },

        ...["daughter", "interest", "investment"].map((type) => ({
          value: type,

          text: this.$t("models.company_group_ownership_state.manual_type", {
            type: this.$t(
              `models.company_group_ownership_state.ownership_type.${type}`
            ),
          }),
        })),
      ];
    },

    type: {
      get() {
        return this.ownershipState.ownership_type_mode == "auto"
          ? "AUTO"
          : this.ownershipState.ownership_type;
      },

      set(value) {
        if (value === "AUTO") {
          this.ownershipState.ownership_type_mode = "auto";
          this.ownershipState.ownership_type = this.automaticType;
        } else {
          this.ownershipState.ownership_type_mode = "manual";
          this.ownershipState.ownership_type = value;
        }
      },
    },
  },

  watch: {
    "ownershipState.shares": {
      handler(shares, prevShares) {
        const updateDefaultsFor = ["owned_shares", "votes", "owned_votes"];

        if (shares) {
          updateDefaultsFor.forEach((key) => {
            if (
              !this.ownershipState[key] ||
              this.ownershipState[key] === prevShares
            ) {
              this.ownershipState[key] = shares;
            }
          });
        }
      },

      deep: true,
    },

    ownershipState: {
      handler(newValue) {
        if (this.type === "AUTO") {
          this.ownershipState.ownership_type = this.automaticType;
        }

        this.$emit("update:modelValue", newValue);
      },

      deep: true,
    },
  },

  methods: {
    // This handles which dates are disabled in the datepicker, by passing in each date individually.
    dateDisabled(date) {
      const states = this.states.filter(
        (a) => a.ownership_id === this.modelValue.company_group_ownership.id
      );
      const currentDatePicker = this.currentDatePicker;

      // This hack is needed because the date we get from the states is
      // based on timezones, so we need to add an hour to the date to
      // make sure we get the correct date.
      date = date.setHours(date.getHours() + 1);

      // If ID exists, we are editing an existing state, and need to check
      // if there's any states before or after the current state, and blocking
      // the dates around the current state.
      if (this.ownershipState.id) {
        const currentStateIndex = states.findIndex(
          (state) => state.id === this.ownershipState.id
        );
        const previousState = states[currentStateIndex - 1];
        const nextState = states[currentStateIndex + 1];

        if (previousState && nextState) {
          if (date >= previousState.end_at && date <= nextState.start_at) {
            return false;
          }

          if (date <= previousState.end_at) {
            return true;
          }
        } else if (previousState && date >= previousState.end_at) {
          return false;
        } else if (previousState && date <= previousState.end_at) {
          return true;
        } else if (nextState && date <= nextState.start_at) {
          return false;
        }
      }

      return states.some((state) => {
        if (this.datesOccupiedByState(date, state)) return true;

        if (currentDatePicker === "start_at") {
          if (this.isAfterEndDate(date)) return true;
          if (
            this.overlapsWithOtherState(date, state, "start_at") &&
            state.id !== this.ownershipState.id
          )
            return true;
        }

        if (currentDatePicker === "end_at") {
          if (this.isBeforeStartDate(date)) return true;
          if (
            this.overlapsWithOtherState(date, state, "end_at") &&
            state.id !== this.ownershipState.id
          )
            return true;
        }
      });
    },

    datesOccupiedByState(date, state) {
      // This returns true if the date is between the start and end date
      // of the state, but not if the state is the current state.
      return (
        state.start_at <= date &&
        state.end_at >= date &&
        state.id !== this.ownershipState.id
      );
    },

    isBeforeStartDate(date) {
      // This returns true if the date is before the start date of the state,
      // when editing an existing state.
      return (
        this.ownershipState.id &&
        this.ownershipState.start_at &&
        date < new Date(this.ownershipState.start_at)
      );
    },

    isAfterEndDate(date) {
      // This returns true if the date is after the end date of the state,
      // when editing an existing state.
      return (
        this.ownershipState.id &&
        this.ownershipState.end_at &&
        date > new Date(this.ownershipState.end_at)
      );
    },

    overlapsWithOtherState(date, state, datePicker) {
      // This checks if the selected date overlaps with another state, and
      // handles it slightly differently depending on which datepicker is
      // currently open.
      if (datePicker === "start_at" && this.ownershipState.end_at) {
        if (date <= state.end_at) return true;
        if (date > new Date(this.ownershipState.end_at)) return true;
      } else if (datePicker === "end_at" && this.ownershipState.start_at) {
        return (
          (new Date(this.ownershipState.start_at) <= state.start_at &&
            date >= state.start_at) ||
          date < new Date(this.ownershipState.start_at)
        );
      }
      return false;
    },

    validateDate(datePicker) {
      if (!this.ownershipState.start_at || !this.ownershipState.end_at) return;

      const start_at = new Date(this.ownershipState.start_at);
      const end_at = new Date(this.ownershipState.end_at);

      if (datePicker === "start_at" && start_at > end_at) {
        this.ownershipState.errors.start_at = this.$t(
          "views.companies.group.ownership.errors.start_date_after_end_date"
        );
      } else if (datePicker === "end_at" && end_at < start_at) {
        this.ownershipState.errors.end_at = this.$t(
          "views.companies.group.ownership.errors.end_date_before_start_date"
        );
      } else {
        if (this.ownershipState.errors) {
          delete this.ownershipState.errors.start_at;
          delete this.ownershipState.errors.end_at;
        }
      }
    },

    calculatePercentage(total, num) {
      if (!total || !num) return 100;
      return BigNumber((num / total) * 100).decimalPlaces(2);
    },
  },
};
</script>
