<template>
  <tr>
    <td class="d-none d-lg-table-cell col-shrink">
      <div
        v-if="!thread.deleted_at"
        class="custom-control custom-checkbox pl-3"
      >
        <be-form-checkbox
          :id="`mark-as-read-${thread.id}`"
          v-model="localChecked"
          name="mark-as-read"
          @change="updateMarkAsRead"
        />
      </div>
    </td>

    <td class="col-shrink">
      <span
        v-if="unreadMessagesCount && unreadMessagesCount > 0"
        class="d-inline-flex align-items-center"
      >
        <be-badge
          v-be-tooltip="
            $t('components.companies.message_threads.notifications_w_count', {
              count: unreadMessagesCount,
            })
          "
          variant="notification"
          pill
        >
          {{ unreadMessagesCount }}
        </be-badge>

        <i
          v-if="urgentMessagesCount && urgentMessagesCount > 0"
          v-be-tooltip="urgentMessagesTooltipText"
          class="fal fa-comment-alt-exclamation fa-fw ml-2"
        />
      </span>
    </td>

    <td>
      <be-link
        :href="url(`/message_threads/${thread.id}`)"
        :class="
          unreadMessagesCount > 0 ? 'font-weight-bold' : 'font-weight-normal'
        "
      >
        {{ threadTitle }}
      </be-link>

      <div class="small mb-0">
        {{ participants }}
      </div>
    </td>

    <td class="col-shrink text-center">
      <i
        v-if="thread.has_attachments"
        v-be-tooltip="$t('models.message_thread.documents_present')"
        class="fal fa-paperclip fa-fw text-muted"
      />
    </td>

    <td class="d-none d-lg-table-cell col-shrink">
      <div v-if="latestMessage">
        {{ getUserName(latestMessage.user_id) }}
        <div class="small">
          {{ $d(new Date(latestMessage.updated_at), "dateTime") }}
        </div>
      </div>
    </td>
  </tr>
</template>

<script>
export default {
  props: {
    thread: {
      type: Object,
      required: true,
    },

    checked: {
      type: Boolean,
      default: false,
    },
  },

  emits: ["marked"],

  data() {
    return {
      localChecked: this.checked,
      unreadMessagesCount: null,
      urgentMessagesCount: null,
    };
  },

  computed: {
    participants() {
      let participants = [];

      let creator = this.getUserName(this.thread.user_id);
      if (creator) {
        participants.push(creator);
      }

      this.visibleParticipants.forEach((participant) => {
        const name = this.getUserName(participant.user_id);

        if (name) {
          participants.push(name);
        }
      });

      if (
        this.thread.invitations.length >
        this.$config.MESSAGE_THREADS.MAX_VISIBLE_INVITES
      ) {
        participants.push(
          this.$i18n.t("components.companies.message_threads.and_more")
        );
      }

      return participants.flat().join(", ");
    },

    messages() {
      if (this.thread.messages) {
        return this.thread.messages;
      }
      return [];
    },

    latestMessage() {
      let length = this.messages.length;
      if (length > 0) {
        return this.messages[length - 1];
      }
      return null;
    },

    visibleParticipants() {
      let invitationsWithoutThreadCreator = this.thread.invitations.filter(
        (invitation) => invitation.user_id != this.thread.user_id
      );
      return invitationsWithoutThreadCreator.slice(
        0,
        this.$config.MESSAGE_THREADS.MAX_VISIBLE_INVITES - 1
      );
    },

    threadTitle() {
      return this.thread.title.slice(0, 80);
    },

    urgentMessagesTooltipText() {
      return this.$t(
        "components.companies.message_threads.list.row.new_urgent_messages",
        { count: this.urgentMessagesCount },
        this.urgentMessagesCount
      );
    },
  },

  watch: {
    checked(new_value) {
      this.localChecked = new_value;
    },
  },

  async created() {
    try {
      const response = await axios.get(
        this.url(`/message_threads/readings/${this.thread.id}`)
      );
      this.unreadMessagesCount = Number(response.data.unread) || null;
      this.urgentMessagesCount = Number(response.data.unread_urgent) || null;
    } catch (error) {
      this.handleError(error);
    }
  },

  methods: {
    updateMarkAsRead(value) {
      this.$emit("marked", this.thread, value);
    },
  },
};
</script>
