<template>
  <div>
    <div v-if="transactions.length > 0" class="table-responsive">
      <be-table :fields="fields" :items="shareholders">
        <template #name="{ item }">
          <a
            v-if="item.policy.edit"
            :href="url(`/shareholder_register/shareholders/${item.id}/edit`)"
          >
            {{ ownerName(item) }}
          </a>

          <template v-else>
            {{ ownerName(item) }}
          </template>
        </template>

        <template #share_mismatch="{ item }">
          <span
            v-if="shareCountMismatch(item)"
            v-be-tooltip="
              $t('components.shareholder_registers.cap_table.share_mismatch')
            "
            class="fas fa-hexagon-exclamation text-danger mr-1"
          />
        </template>

        <template #footer>
          <tr class="text-right">
            <td></td>

            <td></td>

            <td v-if="containsSharesOfType('a_shares')" class="col-shrink">
              {{ shareholderRegister.a_shares }}
            </td>

            <td v-if="containsSharesOfType('b_shares')" class="col-shrink">
              {{ shareholderRegister.b_shares }}
            </td>

            <td v-if="containsSharesOfType('c_shares')" class="col-shrink">
              {{ shareholderRegister.c_shares }}
            </td>

            <td v-if="containsSharesOfType('pref_shares')" class="col-shrink">
              {{ shareholderRegister.pref_shares }}
            </td>

            <td v-if="containsSharesOfType('common_shares')" class="col-shrink">
              {{ shareholderRegister.common_shares }}
            </td>

            <td class="col-shrink">
              {{ shareholderRegister.total_share_count }}
            </td>

            <td class="col-shrink">
              {{ shareholderRegister.total_votes }}
            </td>

            <td></td>

            <td></td>
          </tr>
        </template>
      </be-table>
    </div>

    <div v-else>
      <h2>{{ $t("components.shareholder_registers.cap_table.title") }}</h2>

      <div v-if="shareholderRegister.policy.update" class="row">
        <div class="col-12 col-lg-6">
          <div class="mb-3">
            {{ $t("components.shareholder_registers.cap_table.description") }}
          </div>

          <div>
            <be-button
              v-be-tooltip="{
                title: $t(
                  'components.shareholder_registers.new_actions_disabled'
                ),
                disabled: !disabled,
              }"
              :href="url('/shareholder_register/actions/new')"
              :disabled="disabled"
              variant="outline-primary"
            >
              {{ $t("models.shareholder_register_action.new") }}
            </be-button>
          </div>
        </div>

        <div class="d-none d-lg-block col-lg-6">
          <be-img
            :src="
              imageSrc('onboarding/intro/shareholder_register/cap_table.png')
            "
          />
        </div>
      </div>

      <div v-else class="row">
        <div class="col-6">
          {{ $t("components.shareholder_registers.cap_table.description") }}
        </div>

        <div class="d-none d-lg-block col-lg-6">
          <be-img
            :src="
              imageSrc('onboarding/intro/shareholder_register/cap_table.png')
            "
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import ShareholderRegisterMixins from "@/mixins/shareholderRegisters";

export default {
  mixins: [ShareholderRegisterMixins],

  computed: {
    ...mapGetters({
      shareholderRegister: "shareholder_register/shareholderRegister",
      shareholders: "shareholder_register/currentShareholders",
      transactions: "shareholder_register/transactions",
    }),

    fields() {
      let columns = [
        {
          key: "name",
          label: this.$t("activerecord.attributes.shareholder.name"),
          sortable: true,
        },
        {
          key: "share_mismatch",
          label: "",
          class: "col-shrink",
        },
      ];

      this.$config.SHAREHOLDER_REGISTER.SHARE_TYPES.forEach((shareType) => {
        if (this.containsSharesOfType(shareType)) {
          columns.push({
            key: shareType,

            label: this.$t(
              `models.shareholder_transaction.share_types.${shareType}`
            ),

            class: "col-shrink text-right",
            sortable: true,
          });
        }
      });

      columns.push(
        {
          key: "share_count",
          label: this.$t("activerecord.attributes.shareholder.share_count"),
          class: "col-shrink text-right",
          sortable: true,
        },
        {
          key: "votes",
          label: this.$t("components.shareholder_registers.cap_table.votes"),
          class: "col-shrink text-right",
          sortable: true,
        },
        {
          key: "share_count_percentage",

          label: this.$t(
            "components.shareholder_registers.cap_table.share_count_percentage"
          ),

          class: "col-shrink text-right",
          sortable: true,
          sortByFormatted: true,

          formatter: (value, key, item) =>
            this.shareholderRegister.total_share_count
              ? this.printDecimal(this.percentageOfShares(item))
              : null,
        },
        {
          key: "vote_percentage",

          label: this.$t(
            "components.shareholder_registers.cap_table.part_of_total_votes"
          ),

          class: "col-shrink text-right",
          sortable: true,
          sortByFormatted: true,

          formatter: (value, key, item) => {
            return item.votes >= 0 && this.shareholderRegister.total_votes > 0
              ? this.printDecimal(this.percentageOfVotes(item))
              : 0;
          },
        }
      );

      return columns;
    },

    disabled() {
      return !this.shareholderRegister.policy.create_action;
    },
  },

  methods: {
    // 2 decimal places after converting to percents will support 0.01%
    percentageOfShares(shareholder) {
      const value = BigNumber(
        (shareholder.share_count / this.shareholderRegister.total_share_count) *
          100
      );

      return value;
    },

    percentageOfVotes(shareholder) {
      const value = BigNumber(
        (shareholder.votes / this.shareholderRegister.total_votes) * 100
      );

      return value;
    },

    printDecimal(value) {
      if (value === 0) {
        return "0%";
      } else if (value < 0.01) {
        return "< 0.01%";
      } else {
        return `${value.decimalPlaces(2)}%`;
      }
    },

    ownerName(shareholder) {
      return shareholder.company_name || shareholder.name;
    },

    containsSharesOfType(shareType) {
      return this.shareholders.some(
        (shareholder) => shareholder[shareType] != 0
      );
    },

    sortRows(firstValue, secondValue, key) {
      if (key === "vote_percentage") {
        return (
          this.percentageOfVotes(firstValue) -
          this.percentageOfVotes(secondValue)
        );
      } else if (key === "share_count_percentage") {
        return (
          this.percentageOfShares(firstValue) -
          this.percentageOfShares(secondValue)
        );
      } else {
        return false;
      }
    },
  },
};
</script>
