<template>
  <div class="d-flex flex-column">
    <be-table
      :items="sortedInvitations"
      :fields="fields"
      :per-page="30"
      @row-clicked="(item) => toggleChecked(item)"
    >
      <template #avatar="{ item }">
        <user-avatar :user="getUser(item.user_id)" />
      </template>

      <template #name="{ item }">
        {{ userName(item.user_id) }}
        <div v-if="membershipVia(item.user_id)" class="small">
          {{ membershipVia(item.user_id) }}
        </div>
      </template>

      <template #email="{ item }">
        <be-link :href="`mailto:${userEmail(item.user_id)}`">
          {{ userEmail(item.user_id) }}
        </be-link>
      </template>

      <template #created_at="{ item }">
        {{ dateIfPresent(!!item.deleted_at ? null : item.created_at) }}
      </template>

      <template #always_invited="{ item }">
        <i
          v-if="alwaysInvitedTooltip && userAlwaysInvited(item)"
          v-be-tooltip="alwaysInvitedTooltip"
          class="fal fa-eye text-muted"
        />
      </template>

      <template #options="{ item }">
        <i
          v-if="isOwner(item)"
          v-be-tooltip="$t('attributes.owner')"
          class="fas fa-user"
        />

        <template v-else>
          <be-form-checkbox
            v-if="checkboxInvites && !isInvited(item)"
            :id="`checked-${item.user_id}`"
            :checked="isUserIdChecked(item.user_id)"
            :name="`checked-${item.user_id}`"
            @change="toggleChecked(item)"
          >
            <span class="sr-only">
              {{ $t("components.companies.invitations.check_to_invite") }}
            </span>
          </be-form-checkbox>

          <template v-else-if="uninvitePolicy && isInvited(item)">
            <be-button
              v-be-tooltip="{
                title: alwaysInvitedTooltip,
                disabled: !alwaysInvitedTooltip || !userAlwaysInvited(item),
              }"
              variant="danger"
              :disabled="userAlwaysInvited(item)"
              inline
              @click="uninvite(item)"
            >
              {{ $t("components.companies.invitations.uninvite") }}
            </be-button>
          </template>

          <be-button
            v-else-if="invitePolicy && !isInvited(item)"
            variant="primary"
            inline
            @click="invite(item)"
          >
            {{ $t("components.companies.invitations.invite") }}
          </be-button>
        </template>
      </template>
    </be-table>

    <div class="mt-2 d-md-flex justify-content-start">
      <div v-if="$slots.buttons">
        <slot name="buttons" />
      </div>

      <div v-if="checkboxInvites" class="mr-md-2">
        <be-button
          v-if="!isEveryoneChecked"
          variant="outline-secondary"
          :disabled="availableUsersToInvite.length == 0"
          @click="checkEveryone"
        >
          {{ $t("buttons.toggle_all_selection.select_all") }}
        </be-button>

        <be-button
          v-else
          variant="outline-secondary"
          :disabled="availableUsersToInvite.length == 0"
          @click="uncheckEveryone"
        >
          {{ $t("buttons.toggle_all_selection.deselect_all") }}
        </be-button>
      </div>

      <membership-invitations
        v-if="
          !hideMembershipInvitationsLink &&
          checkPolicy('create_membership') &&
          $platform.features.user_administration
        "
        :button-text="
          $t('components.companies.invitations.add_users_to_company')
        "
        button-variant="outline-secondary"
        :display-membership-invitation-warning-message="
          displayMembershipInvitationWarningMessage
        "
        @updated="membershipsUpdated"
      />
    </div>
  </div>
</template>

<script>
import MembershipInvitations from "./memberships/MembershipInvitations.vue";
import { mapGetters } from "vuex";

export default {
  components: {
    MembershipInvitations,
  },

  props: {
    invitations: {
      type: Array,
      required: true,
    },

    owners: {
      type: Array,
      default: () => [],
    },

    checkboxInvites: {
      type: Boolean,
      default: false,
    },

    invitePolicy: {
      type: Boolean,
      required: true,
    },

    uninvitePolicy: {
      type: Boolean,
      required: true,
    },

    invitationHeader: {
      type: String,
      required: false,
      default: null,
    },

    displayMembershipInvitationWarningMessage: {
      type: Boolean,
      required: false,
      default: false,
    },

    checkedUserIds: {
      type: Array,
      required: true,
    },

    alwaysInvitedUserIds: {
      type: Array,
      required: false,
      default: () => [],
    },

    alwaysInvitedTooltip: {
      type: String,
      required: false,
      default: "",
    },

    hideMembershipInvitationsLink: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  emits: [
    "invite",
    "memberships-updated",
    "resend",
    "uninvite",
    "update:checkedUserIds",
    "updated",
  ],

  data() {
    return {
      checkedEveryone: false,
    };
  },

  computed: {
    ...mapGetters({
      company: "company/getCompany",
      companyUsers: "company/users",
    }),

    isEveryoneChecked() {
      return this.availableUsersToInvite.every((availableUserToInvite) =>
        this.isUserIdChecked(availableUserToInvite.user_id)
      );
    },

    sortedInvitations() {
      let owners = [];
      let others = [];

      this.invitations.forEach((invitation) => {
        if (this.isOwner(invitation)) {
          owners.push(invitation);
        } else if (!this.userRemoved(invitation.user_id)) {
          others.push(invitation);
        }
      });

      return owners.concat(others);
    },

    fields() {
      let fields = [
        { key: "avatar", label: "", class: "shrink" },
        {
          key: "name",
          label: this.translateAttribute("user", "name"),
        },
        {
          key: "email",
          label: this.translateAttribute("user", "email"),
        },
        {
          key: "created_at",

          label:
            this.invitationHeader ||
            this.translateAttribute("invitation", "created_at"),

          class: "",
        },
      ];

      if (this.alwaysInvitedTooltip && this.alwaysInvitedUserIds.length > 0) {
        fields.push({
          key: "always_invited",
          label: "",
          class: "text-center",
        });
      }

      let allow = this.invitations.some((invitation) => {
        return (
          invitation.policy.create ||
          invitation.policy.destroy ||
          this.isOwner(invitation)
        );
      });

      if (allow) {
        fields.unshift({
          key: "options",
          label: "",
          class: "text-center col-shrink",
        });
      }
      return fields;
    },

    availableUsersToInvite() {
      return this.invitations.filter(
        (invitation) => !this.isInvited(invitation) && !this.isOwner(invitation)
      );
    },
  },

  methods: {
    toggleChecked(invitation) {
      const userId = invitation.user_id;

      const localCheckedUserIds = this.cloneDeep(this.checkedUserIds);

      const index = localCheckedUserIds.indexOf(userId);
      if (index > -1) {
        localCheckedUserIds.splice(index, 1);
      } else {
        localCheckedUserIds.push(userId);
      }

      this.$emit("update:checkedUserIds", localCheckedUserIds);
    },

    isUserIdChecked(userId) {
      return this.checkedUserIds.includes(userId);
    },

    checkEveryone() {
      const userIds = this.availableUsersToInvite.map(
        (availableUserToInvite) => availableUserToInvite.user_id
      );

      this.$emit("update:checkedUserIds", userIds);
    },

    uncheckEveryone() {
      this.$emit("update:checkedUserIds", []);
    },

    dateIfPresent(date) {
      if (date) {
        return this.$d(new Date(date), { format: "mini" });
      }
      return "-";
    },

    isOwner(invitation) {
      return this.owners.some((user) => user.id === invitation.user_id);
    },

    isInvited(invitation) {
      return !!invitation.deleted_at == false && !!invitation.created_at;
    },

    invite(invitation) {
      this.$emit("invite", invitation.user_id);
    },

    resend(invitation) {
      this.$emit("resend", invitation);
    },

    uninvite(invitation) {
      this.$emit("uninvite", invitation);
    },

    userRemoved(user_id) {
      return !this.companyUsers.find(
        (companyUser) => companyUser.id == user_id
      );
    },

    membershipsUpdated() {
      this.$emit("memberships-updated");
    },

    isAdminOrObserver(invitation) {
      return !!this.company.memberships.find(
        (membership) =>
          membership.user.id === invitation.user_id &&
          ["admin", "observer"].includes(membership.policy_level)
      );
    },

    canUninviteUser(invitation) {
      return (
        invitation.policy.create && invitation.user_id !== this.$currentUser.id
      );
    },

    userAlwaysInvited(invitation) {
      return this.alwaysInvitedUserIds.includes(invitation.user_id);
    },

    userName(user_id) {
      return this.getUser(user_id)?.name;
    },

    userEmail(user_id) {
      return this.getUser(user_id)?.email;
    },

    membershipVia(user_id) {
      return this.getMembershipByUserId(user_id)?.via;
    },
  },
};
</script>
