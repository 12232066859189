<template>
  <be-modal
    :id="modalId"
    :title="$t('components.documents.document_row.send_for_signatures')"
    size="xl"
    @ok="handleOk"
  >
    <template #footer="{ ok, cancel }">
      <be-button variant="light" @click="cancel">
        {{ $t("buttons.titles.cancel") }}
      </be-button>

      <be-button
        v-be-tooltip="{
          title: disabledOkButtonTooltip,
          disabled: !disableOk,
        }"
        variant="primary"
        :disabled="disableOk"
        :loading="sending"
        icon="fa-envelope"
        @click="ok"
      >
        {{ okTitle }}
      </be-button>
    </template>

    <div class="table-responsive">
      <table class="table table-hover">
        <thead>
          <tr>
            <th class="col-shrink"></th>

            <th class="">
              {{ $t("activerecord.attributes.document.filename") }}
            </th>

            <th class="col-shrink">
              {{ $t("activerecord.attributes.document.created_at") }}
            </th>
          </tr>
        </thead>

        <tbody>
          <tr>
            <td class="col-shrink text-muted text-center">
              <i :class="doc.fontawesome_icon" />
            </td>

            <td class="">
              <document-link
                :document-id="doc.id"
                :filename="doc.filename"
                :show-icon="false"
                :title="doc.title"
              />
            </td>

            <td class="col-shrink">
              {{ doc.created_at ? $d(new Date(doc.created_at), "date") : "" }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <be-form-group
      :label="$t('components.documents.document_row.message')"
      :label-for="`${modalId}-signature-message`"
    >
      <be-form-textarea
        :id="`${modalId}-signature-message`"
        v-model="message"
        rows="3"
        max-rows="20"
      />
    </be-form-group>

    <h3 v-if="externalSignatoriesEnabledByUser && signatories.length > 0">
      {{ $t("components.documents.signature_form.internal_signatories") }}
    </h3>

    <be-table
      v-if="signatories.length > 0"
      :items="signatories"
      :fields="userFields"
      @row-clicked="(item) => toggleUserSelect(item)"
    >
      <template #selected="{ item: { delta } }">
        <be-form-checkbox
          :id="`signatory-${delta}-selected`"
          v-model="signatories[delta].selected"
        />
      </template>

      <template #avatar="{ item }">
        <user-avatar :user="item" />
      </template>

      <template #name="{ item }">
        <div>
          {{ item.name }}
        </div>

        <membership-subtitle :user-id="item.userId" class="small" />
      </template>

      <template #title="{ item: { delta } }">
        <be-form-group
          :label="translateAttribute('membership', 'role')"
          :label-for="`signatory-${delta}-title`"
          label-sr-only
          class="m-0"
        >
          <be-form-input
            :id="`signatory-${delta}-title`"
            v-model="signatories[delta].title"
            @keydown.space.stop
          />
        </be-form-group>
      </template>

      <template #auditor="{ item: { delta } }">
        <be-form-checkbox
          :id="`signatory-${delta}-auditor`"
          v-model="signatories[delta].auditor"
          class="col-shrink"
          :aria-label="$t('memberships.roles.auditor')"
        />
      </template>

      <template #email="{ item }">
        {{ item.email }}
      </template>
    </be-table>

    <be-button
      variant="outline-secondary"
      size="sm"
      class="mb-3 mt-0"
      @click="onToggleAllButton"
    >
      {{ toggleAllButtonTitle }}
    </be-button>

    <be-form-checkbox
      :id="`${modalId}-external-signatories`"
      v-model="externalSignatoriesEnabledByUser"
    >
      {{ $t("components.documents.signature_form.allow_external_signatories") }}
    </be-form-checkbox>

    <div v-if="externalSignatoriesEnabledByUser">
      <h3 class="mt-3">
        {{ $t("components.documents.signature_form.external_signatories") }}
      </h3>

      <be-table
        :fields="externalSignatoriesFields"
        :items="externalSignatories"
        class="mb-2 external-signatories-table"
      >
        <template #name="{ index }">
          <be-form-group
            class="m-0"
            :label="translateAttribute('user', 'name')"
            :label-for="`external-${index}-name`"
            label-sr-only
          >
            <be-form-input
              :id="`external-${index}-name`"
              v-model="externalSignatories[index].name"
              :placeholder="translateAttribute('user', 'name')"
            />
          </be-form-group>
        </template>

        <template #title="{ index }">
          <be-form-group
            :label="translateAttribute('membership', 'role')"
            :label-for="`external-${index}-title`"
            label-sr-only
            class="m-0"
          >
            <be-form-input
              :id="`external-${index}-title`"
              v-model="externalSignatories[index].title"
              :placeholder="translateAttribute('membership', 'role')"
              @keydown.space.stop
            />
          </be-form-group>
        </template>

        <template #email="{ index }">
          <be-form-group
            class="m-0"
            :label="translateAttribute('user', 'email')"
            :label-for="`external-${index}-email`"
            label-sr-only
          >
            <be-form-input
              :id="`external-${index}-email`"
              v-model="externalSignatories[index].email"
              type="email"
              :placeholder="translateAttribute('user', 'email')"
              trim
              :state="validateEmail(externalSignatories[index].email)"
              debounce="500"
            />

            <be-form-invalid-feedback
              v-if="!validateEmail(externalSignatories[index].email)"
              :state="validateEmail(externalSignatories[index].email)"
            >
              {{ $t("components.documents.signature_form.invalid_email") }}
            </be-form-invalid-feedback>
          </be-form-group>
        </template>

        <template #auditor="{ index }">
          <be-form-group
            :label="$t('memberships.roles.auditor')"
            :label-for="`external-${index}-auditor`"
            label-sr-only
            class="m-0"
          >
            <be-form-checkbox
              :id="`external-${index}-auditor`"
              v-model="externalSignatories[index].auditor"
              class="m-0"
              :aria-label="$t('memberships.roles.auditor')"
            />
          </be-form-group>
        </template>

        <template #remove="{ index }">
          <be-button
            v-be-tooltip="$t('buttons.titles.remove')"
            size="sm"
            variant="danger"
            icon="fa-times"
            inline
            @click="externalSignatories.splice(index, 1)"
          />
        </template>
      </be-table>
    </div>

    <be-form-checkbox
      :id="`${modalId}-external-access`"
      v-model="externalAccess"
    >
      {{ $t("components.documents.signature_form.external_access") }}
    </be-form-checkbox>

    <be-form-checkbox
      :id="`${modalId}-inform-all-signatories`"
      v-model="informAllSignatories"
      class="mb-3"
    >
      {{ $t("components.documents.signature_form.inform_all_signatories") }}
      <i
        v-be-tooltip="
          $t(
            'components.documents.signature_form.inform_all_signatories_tooltip'
          )
        "
        class="fal fa-question-circle ml-1"
      />
    </be-form-checkbox>

    <be-alert v-if="selectedUserNames.length > 0" variant="info">
      <div>
        {{ $t("components.documents.signature_form.will_be_sent_to") }}
      </div>

      <div>
        {{ selectedUserNames.join(", ") }}
      </div>
    </be-alert>
  </be-modal>
</template>

<script>
import TextUtilities from "@/mixins/textUtilities";
import MembershipSubtitle from "@/components/shared/MembershipSubtitle.vue";
import { createNamespacedHelpers } from "vuex";

const { mapMutations: mapAnnualReportMutations } =
  createNamespacedHelpers("annual_reports");

export default {
  components: {
    MembershipSubtitle,
  },

  mixins: [TextUtilities],

  props: {
    availableMemberships: {
      required: true,
      type: Array,
    },

    annualReport: {
      required: true,
      type: Object,
    },

    doc: {
      required: true,
      type: Object,
    },

    docType: {
      required: false,
      type: String,
      default: "annual_report",
    },
  },

  data() {
    let delta = 0;
    const signatories = this.availableMemberships.map((membership) => ({
      userId: membership.user.id,
      name: membership.user.name,
      email: membership.user.email,
      title: this.defaultTitle(membership),
      auditor: membership.role == "auditor" && this.annualReport.auditor_needed,
      selected: this.isSelectedByDefault(membership),
      delta: delta++,
    }));

    return {
      signatories,
      externalSignatories: this.defaultExternalSignatories(),
      informAllSignatories: false,
      message: "",
      sending: false,
      modalId: `annual-report-signature-form-${this.generateUuid()}`,

      externalSignatoriesEnabledByUser: !signatories.some(
        ({ selected }) => selected
      ),

      externalAccess: false,
    };
  },

  computed: {
    userFields() {
      return [
        { key: "selected", label: "", class: "col-shrink" },
        { key: "avatar", label: "", class: "col-shrink" },

        {
          key: "name",
          label: this.translateAttribute("user", "name"),
        },

        {
          key: "auditor",
          label: this.$t("memberships.roles.auditor"),
          class: "col-shrink",

          disabled:
            !this.annualReport.auditor_needed ||
            this.docType != "annual_report",
        },

        {
          key: "title",
          label: this.translateAttribute("membership", "role"),
        },

        {
          key: "email",
          label: this.translateAttribute("user", "email"),
        },
      ].filter(({ disabled }) => !disabled);
    },

    externalSignatoriesFields() {
      return [
        {
          key: "name",
          label: this.translateAttribute("user", "name"),
        },

        {
          key: "auditor",
          label: this.$t("memberships.roles.auditor"),
          class: "col-shrink",

          disabled:
            !this.annualReport.auditor_needed ||
            this.docType != "annual_report",
        },

        {
          key: "title",
          label: this.translateAttribute("membership", "role"),
        },

        {
          key: "email",
          label: this.translateAttribute("user", "email"),
        },

        { key: "remove", label: "", class: "col-shrink" },
      ].filter(({ disabled }) => !disabled);
    },

    isFoundation() {
      return this.$currentCompany.type_of_organization == "foundation";
    },

    signatureNotice() {
      if (this.isFoundation) {
        return this.$t(
          "components.annual_reports.upload_tab.foundation.signatures_notice"
        );
      } else {
        return this.$t(
          "components.annual_reports.upload_tab.signatures_notice"
        );
      }
    },

    selectedUserNames() {
      const names = this.signatories
        .filter((signatory) => signatory.selected)
        .map((signatory) => signatory.name);

      if (this.externalSignatoriesEnabledByUser) {
        this.externalSignatories.forEach((externalSignatory) => {
          if (externalSignatory.name.length > 0 && externalSignatory.email) {
            names.push(externalSignatory.name);
          }
        });
      }

      return names;
    },

    externalSignatoriesDescription() {
      if (this.doc.owner_type === "AnnualReport") {
        return this.$t(
          "components.annual_reports.upload_tab.external_auditor_forbidden"
        );
      }
      return null;
    },

    okTitle() {
      if (this.sending) {
        return this.$i18n.t("buttons.states.sending");
      }

      return this.$i18n.t("buttons.titles.send");
    },

    hasSelectedSignatories() {
      if (this.docType == "annual_report") {
        return (
          this.signatories.some(
            ({ selected, auditor }) => selected && !auditor
          ) ||
          this.externalSignatories.some(
            ({ email, auditor }) => email && !auditor
          )
        );
      } else {
        return (
          this.signatories.some(({ selected }) => selected) ||
          this.externalSignatories.some(({ email }) => email)
        );
      }
    },

    hasSelectedAuditors() {
      return (
        this.signatories.some(({ selected, auditor }) => selected && auditor) ||
        this.externalSignatories.some(({ email, auditor }) => email && auditor)
      );
    },

    disableOk() {
      if (
        this.annualReport.auditor_needed &&
        this.docType == "annual_report" &&
        !this.hasSelectedAuditors
      ) {
        return true;
      }
      if (!this.hasSelectedSignatories) {
        return true;
      }
      if (this.sending) {
        return true;
      }
      return false;
    },

    disabledOkButtonTooltip() {
      if (!this.hasSelectedSignatories) {
        return this.$t(
          "components.documents.signature_form.not_enough_signatories"
        );
      }

      if (this.annualReport.auditor_needed && !this.hasSelectedAuditors) {
        return this.$t(
          "components.documents.signature_form.not_enough_auditors"
        );
      }

      return null;
    },

    allSelected() {
      return this.signatories.every((signatory) => signatory.selected);
    },

    toggleAllButtonTitle() {
      return this.allSelected
        ? this.$t("buttons.toggle_all_selection.deselect_all")
        : this.$t("buttons.toggle_all_selection.select_all");
    },
  },

  watch: {
    doc(value) {
      if (value.id) {
        this.$beModal.show(this.modalId);
      } else {
        this.$beModal.hide(this.modalId);
      }
    },

    externalSignatories: {
      deep: true,

      handler(value) {
        if (value.length == 0 || value[value.length - 1].name.length > 0) {
          this.externalSignatories.push({
            name: "",
            email: "",

            metadata: {
              title: "",
              auditor: false,
            },
          });
        }
      },
    },
  },

  methods: {
    ...mapAnnualReportMutations(["setAnnualReport"]),

    defaultTitle(membership) {
      const titles = [];
      // Not using translations, because this should always be in Swedish, and
      // use terms that are specific to Swedish conventions for annual reports.
      if (membership.role === "ceo") {
        titles.push("Verkställande Direktör");
      }
      if (membership.role == "auditor") {
        titles.push("Auktoriserad Revisor");
      }
      if (membership.function == "chairman") {
        titles.push("Styrelseordförande");
      }
      if (membership.function == "regular") {
        titles.push("Styrelseledamot");
      }
      if (membership.function == "alternate") {
        titles.push("Styrelsesuppleant");
      }
      return titles.join(", ");
    },

    isSelectedByDefault(membership) {
      switch (this.docType) {
        case "annual_report":
          return (
            membership.function == "chairman" ||
            membership.role == "auditor" ||
            membership.function == "regular" ||
            membership.role == "ceo"
          );
        case "board_statement":
          return (
            membership.function == "chairman" &&
            this.annualReport.annual_report_document?.signatures.some(
              (signature) => signature.user_id == membership.user.id
            )
          );
        case "audit_report":
          return membership.function == "auditor";
        default:
          return false;
      }
    },

    defaultExternalSignatories() {
      let externals = [];

      switch (this.docType) {
        case "board_statement":
          externals = this.annualReport.annual_report_document?.signatures
            .filter(
              (signature) =>
                signature.user_id == null &&
                signature.title == "Styrelseordförande"
            )
            .map(({ user_name, email }) => ({
              name: user_name,
              email,
              title: "Styrelseordförande",
              auditor: false,
            }));
          break;
        case "audit_report":
          externals = this.annualReport.annual_report_document.signatures
            .filter(
              (signature) => signature.user_id == null && signature.auditor
            )
            .map(({ user_name, email, title }) => ({
              name: user_name,
              email,
              title,
              auditor: true,
            }));
          break;
      }
      externals ||= [];
      externals.push({
        name: "",
        email: "",
        title: "",
        auditor: false,
      });
      return externals;
    },

    resetForm() {
      this.message = "";
      this.externalSignatoriesEnabledByUser = false;
      this.externalAccess = false;
      this.informAllSignatories = false;
    },

    onToggleAllButton() {
      if (this.allSelected) {
        this.deselectAll();
      } else {
        this.selectAll();
      }
    },

    selectAll() {
      this.signatories.forEach((signatory) => {
        signatory.selected = true;
      });
    },

    deselectAll() {
      this.signatories.forEach((signatory) => {
        signatory.selected = false;
      });
    },

    toggleUserSelect(item) {
      const signatory = this.signatories.find(
        ({ userId }) => userId == item.userId
      );
      signatory.selected = !signatory.selected;
    },

    handleOk(beModalEvt) {
      // Prevent modal from closing
      beModalEvt.preventDefault();
      this.submit();
    },

    // Will return the response from the call, which is infact the AnnualReport.
    documentUpdated(data) {
      this.$store.commit("annual_reports/setAnnualReport", data);
    },

    async submit() {
      const signatories = this.signatories.filter(({ selected }) => selected);

      const signaturesMetadata = Object.fromEntries(
        signatories.map((signatory) => [
          signatory.userId,
          {
            title: signatory.title,
            auditor: signatory.auditor,
          },
        ])
      );

      const userIds = signatories.map(({ userId }) => userId);

      const externalSignatories = this.externalSignatoriesEnabledByUser
        ? this.externalSignatories.filter(({ name }) => name.length > 0)
        : [];

      const params = {
        signature_case: {
          document_id: this.doc.id,
          user_ids: userIds,
          signatures_metadata: signaturesMetadata,
          external_signatories: externalSignatories,
          allowed_signature_types: ["electronicid"],
          message: this.message,
          inform_all_signatories: this.informAllSignatories,

          external_access:
            this.externalAccess && this.externalSignatoriesEnabledByUser,
        },
      };

      try {
        this.sending = true;
        const { data } = await axios.post(
          this.annualReport.paths.signature_requests,
          params
        );
        this.setAnnualReport(data);
      } catch (error) {
        this.handleError(error);
      } finally {
        this.sending = false;
        this.$nextTick(() => {
          this.$beModal.hide(this.modalId);
        });
      }
    },
  },
};
</script>
