<template>
  <be-modal
    ref="external-attendance-form"
    size="sm"
    :title="modalTitle"
    :ok-title="okText"
    @ok="submit"
  >
    <be-form-group
      label-for="name"
      :label="$t('activerecord.attributes.attendance.name')"
      :error="getErrors(localAttendance, ['name'])"
    >
      <be-form-input
        id="name"
        v-model="localAttendance.name"
        required
        @change="clearErrors(localAttendance, ['name'])"
      />
    </be-form-group>

    <be-form-group
      label-for="email"
      :label="$t('attributes.email')"
      :required="localAttendance.signatory"
      :description="$t('models.attendance.hints.email_external')"
      :error="getErrors(localAttendance, ['email'])"
    >
      <be-form-input
        id="email"
        v-model="localAttendance.email"
        type="email"
        @change="clearErrors(localAttendance, ['email'])"
      />
    </be-form-group>

    <be-form-checkbox
      id="signatory"
      v-model="localAttendance.signatory"
      :description="$t('models.attendance.hints.signatory_external')"
      :disabled="localAttendance.reviewer || localAttendance.secretary"
      @change="clearErrors(localAttendance, ['signatory'])"
    >
      {{ $t("activerecord.attributes.attendance.signatory") }}
    </be-form-checkbox>
  </be-modal>
</template>

<script>
export default {
  props: {
    attendance: {
      type: Object,
      required: true,
    },
  },

  emits: ["update"],

  data() {
    return {
      localAttendance: this.cloneDeep(this.attendance),
    };
  },

  computed: {
    modalTitle() {
      if (this.attendance.id) {
        return this.$t("buttons.titles.edit_w_type", {
          type: this.$t("activerecord.models.attendance.one").toLowerCase(),
        });
      } else {
        return this.$t("components.meetings.material.attendances.add_attendee");
      }
    },

    okText() {
      if (this.attendance.id) {
        return this.$t("buttons.titles.save");
      } else {
        return this.$t("buttons.titles.add");
      }
    },
  },

  watch: {
    attendance: {
      handler() {
        this.localAttendance = this.cloneDeep(this.attendance);
        this.$refs["external-attendance-form"].show();
      },

      deep: true,
    },
  },

  mounted() {
    this.$refs["external-attendance-form"].show();
  },

  methods: {
    submit(event) {
      event.preventDefault();
      this.$emit("update", this.localAttendance);
    },
  },
};
</script>
